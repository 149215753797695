module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-92473417-7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PNQKCKG","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"includeInDevelopment":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MeetYouAt420.com","short_name":"meet-you-at-420","start_url":"/","background_color":"#FFF","theme_color":"#548f6f","display":"minimal-ui","icon":"src/images/p.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8e31b8b47c618ed73e5b31011d1de037"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
